import React from 'react';
import { Link } from 'gatsby';

// components
import Container from '../components/Container';

// css
import css from '../css/pages/success.module.css';

export default ({ data }) => {
  var title = 'Děkuji za objednávku';
  var successText = 'Objednávku vám co nejdříve pošlu a kdyby cokoliv tak se vám ozvu';
  var backHome = 'Jít zpět na hlavní stránku';

  return (
    <Container>
      <section className={css.success}>
        <div className="wrap-s ta-center">
          <h1>{title}</h1>
          <p>{successText}</p>
          <p className="fs-large m-2 fw-medium">
            <Link to="/">{backHome}</Link>
          </p>
        </div>
      </section>
    </Container>
  );
};
